@tailwind base;
@tailwind components;
@tailwind utilities;


/* slick slider css files */
@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";



@font-face {
    font-family: "Inter";
    src: url("../public/assets/fonts/Inter/Inter-Regular.ttf");
    font-weight: 500;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Inter";
    src: url("../public/assets/fonts/Inter/Inter-SemiBold.ttf");
    font-weight: 600;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Inter";
    src: url("../public/assets/fonts/Inter/Inter-Bold.ttf");
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Inter";
    src: url("../public/assets/fonts/Inter/Inter-Black.ttf");
    font-weight: 900;
    font-style: normal;
  }
  
  

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.slick-slider .slick-track, .slick-slider .slick-list {
  overflow: unset;
}

.slick-track {
  margin: 0;
}

.multi-slider {
  .slick-list{
      padding-left: 0px!important; /* half partial*/
      /* .slick-track {
          margin-left: 0;
          margin-right: unset;
          display: flex;
          justify-content: center;
          align-items: start;
          height: 100%;
      } */
      .slick-slide {
          padding: 0 16px 0 0;
          position: relative;
          /* min-height: 220px; */
          /* @media (max-width: 991px) {
              min-height: 175px;
          } */
          /* > div {
              top: 0;
              bottom: 0;
              left: 0;
              right: 14px;
              position: absolute;
              border-radius: 6px;
              display: flex;
              >div {
                  display: flex!important;
                  flex-direction: column;
                  justify-content: space-between;
              }
          } */
      }
  }
}
.slick-list{
  position: relative;
  .slick-track {
      display: flex !important;
      align-items: stretch!important;
  }
  .slick-slide {
      padding: 0 10px 0 0;
      /* height: 100% !important; */
      
      > div > div {
          height: 100%;
      }
      h3 {
          font-size: 15px;
          font-weight: 500;
          line-height: 20px;
      }
      
  }
}
