.reverb-static-lightbox {
    height: 1600px !important;
    width: 100%;
    max-width: 1440px !important;
    margin: auto;
    display: inline-block;
    vertical-align: top;
}

@media (min-width: 601px) {
    .reverb-static-lightbox {
        height: 1850px !important;
        max-width: 1440px !important;
        margin: auto;
    }
}

/* Responsive layout - makes a one column layout instead of a two-column layout */
@media (min-width: 1024px) {
    .reverb-static-lightbox {
        height: 1630px !important;
        max-width: 1440px !important;
        margin: auto;
    }
}